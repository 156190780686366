import { TableBase } from '../../tables/table_base'

class UnaccountedVehicleMileage extends TableBase {
  constructor () {
    super('unaccounted_vehicle_mileage')
  }

  paintTable ($missingMileage, unaccounted_vehicle_mileage) {
    $missingMileage.empty()
    let hasRows = false

    for (const i in unaccounted_vehicle_mileage) {
      hasRows = true
      const row = unaccounted_vehicle_mileage[i]
      const reg = row.reg
      const id = row.id
      const start = moment(row.start).format('Do MMM YYYY HH:mm')
      const end = moment(row.end).format('Do MMM YYYY HH:mm')
      const url = `${app.CACHE.URL_ADMIN_LIST}tco_mis_mil/filter/vehicle_id:${row.id}`;
      const $row = $('<tr>')
      const $infoCell = $(`<a href="${url}">`).text(reg);
      const $regCell = $('<td>').append($infoCell);
      const $timeCell = $('<td>').text(`${start} - ${end}`);
      const $distanceCell = $('<td>').text(row.distance);
      const commentHTML = `<a href="${url}"><i class="fa fa-link"></i></a>`
      const $commentCell = $('<td>').html(commentHTML)
      $row.append($regCell, $timeCell, $distanceCell, $commentCell)
      $missingMileage.append($row)
    }

    if (!hasRows) {
      $missingMileage.html('<tr><td colspan="5">No unaccounted mileage to display</td></tr>')
      return false
    }

    return true
  }
}

$(() => {
  if (app && app.DASHBOARD_CORE) {
    new UnaccountedVehicleMileage()
  } else {
    console.warn('DashboardCore not initalized.')
  }
})